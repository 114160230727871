import Vue from 'vue'
import VueRouter from 'vue-router'
import bo_routes from './bo_routes'
import store from '@/store/index'
const state = store.state

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'BaseBackoffice',
    component: () => import('@/layout/BaseBackoffice.vue'),
    redirect: { name: 'Login' },
    children: bo_routes,
  },
  {
    path: '*',
    redirect: {
      name: 'Dashboard'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters['authenticated']
  const user = state.user

  if (to.meta.isDisplay) {
    return next()

  } else if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    // Redirect if logged in
    return next({ name: 'Dashboard' })

  } else if (!to.meta.redirectIfLoggedIn && !isLoggedIn) {
    // Redirect if not logged in
    return next({ name: 'Login' })

  } else if (
    isLoggedIn &&
    to.meta.menu &&
    (user.menuRole || []).indexOf(to.meta.menu) <= -1
  ) {

    const userNotExists = !user.id

    if (userNotExists || (to.name !== from.name && from.name !== null)) {
      store.dispatch('attempt').then(() => {
        const isLoggedInNew = store.getters['authenticated']
        if (!isLoggedInNew && isLoggedIn) {
          return next({ name: 'Login' })
        }
      })
    }

    // login AND not allowed menu > to first allowed menu
    if (store.getters['canManageAll']) {
      return next()
    } else {
      return next({ name: user.menuRole[0] })
    }
  }

  return next()
})

router.afterEach(() => {
  const appLoading = document.getElementById('splashScreen')
  if (appLoading) {
    appLoading.classList.add('loaded')
    document.body.classList.remove('overflow-hidden')
  }
})

export default router
