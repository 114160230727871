<template>
  <div id="app">
    <router-view />
    <ImageFilter />
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global'
import Gen from '@/libs/Gen'
import _ from 'lodash'

import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/messaging'

export default {
  extends: GlobalVue,
  mounted() {
    // dipindah ke load data /profile
    let variable = Gen.getStorage("bo_variable", {})
    _.forEach(variable, (v, k) => { this.$root[k] = v })
    
    /* 
    try {
      let res = await Gen.apiRest("/variable", {})
      Gen.putStorage("bo_variable", res.data)
      _.forEach(res.data, (v, k) => { this.$set(this.$root, k, v) })
    }catch{
      console.log('error')
    } */

    setTimeout(() => {
      this.initFirebase() // lumayan smooth setelah dikasih timeout
    }, 5000)
  },
  methods: {
    initFirebase() {
      if ('serviceWorker' in navigator) {
        const firebaseConfig = {
          apiKey: "AIzaSyCa6y-FpPnsYn4tJZj_7ASY38CtXfyyU7k",
          authDomain: "rsh-emr.firebaseapp.com",
          projectId: "rsh-emr",
          storageBucket: "rsh-emr.appspot.com",
          messagingSenderId: "173815151547",
          appId: "1:173815151547:web:b9a93886891b46a472229e",
          measurementId: "G-T1VJLF7JXV"
        }

        // console.log(firebaseConfig)
        firebase.initializeApp(firebaseConfig)
        try {

          // console.log(Notification.permission)

          navigator.serviceWorker
            .register(process.env.VUE_APP_PUBLIC_PATH == '/emr' ? '/emr/firebase-messaging-sw.js' : "/firebase-messaging-sw.js", {
              scope: "firebase-cloud-messaging-push-scope",
            })
            .then(registration => {
              const messaging = firebase.messaging()
              messaging.getToken({
                serviceWorkerRegistration: registration
              }).then(token => {
                // This will insert token to db
                console.log(token)
                Gen.apiRest('/do/' + 'Users', {
                  data: {
                    type: 'update-token',
                    token: token,
                  }
                }, 'POST')
              }).catch(() => { })

              Vue.prototype.$messaging = messaging

            })
            .catch(() => { })
        } catch (err) {
          console.warn(err)
        }
      }
    }
  }
}
</script>