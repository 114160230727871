import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'
import Gen from '@/libs/Gen'

Vue.use(Vuex)

let store = new Vuex.Store({
  state: {
    // variables
    production: process.env.VUE_APP_MODE == "production",
    baseUrl: process.env.VUE_APP_MODE == 'local' ? location.origin : process.env.VUE_APP_BASE_URL,
    apiUrl: process.env.VUE_APP_API_URL,
    webTitle: process.env.VUE_APP_TITLE,
    userToken: null,
    user: {},
    data: {},
    boSidebarOpen: true,
  },
  getters: {
    // computed
    authenticated(state) {
      return state.userToken && state.user?.id
    },
    canManageAll(state, getters) {
      if (getters['authenticated']) {
        return state.user?.canManageAll == 'Y'
      } else {
        return false
      }
    },
  },
  mutations: {
    // update action
    setUserToken(state, data = {}) { state.userToken = data.token },
    updateData(state, data = {}) { state.data = data.user },
    updateUser(state, data = {}) {
      state.user = data.user
      localStorage.setItem('u_menu', JSON.stringify(state.user?.menuRole || []))
      localStorage.setItem('u_menu_all', state.user?.levelId == 1 ? 'Y' : 'N')
    },
    updateUserToken(state, data = {}) {
      state.userToken = data.token
      if (data.isRememberMe) {
        Gen.setCookie("u_auth" + (process.env.VUE_APP_COOKIE_AUTH || ""), data.token, 30 * 12)
        localStorage.setItem("isRemember", 1)
      } else {
        Gen.setCookie("u_auth" + (process.env.VUE_APP_COOKIE_AUTH || ""), data.token, 30 * 3)
        localStorage.setItem("isRemember", 0)
      }
    },
    updateVariable(state, data = {}) {
      localStorage.setItem('bo_variable', JSON.stringify(data.app || {}))
    },
    logoutUserToken: function (state) {
      state.userToken = null
      state.user = {}
      Gen.setCookie("u_auth" + (process.env.VUE_APP_COOKIE_AUTH || ""), "")
      localStorage.removeItem('u_menu')
      localStorage.removeItem('u_menu_all')
      localStorage.removeItem('isRemember')
    },
    toggleBoSidebar(state, val) {
      if (window.innerWidth < 768) {
        document.querySelector('body').classList.toggle('show-sidebar')
        document.querySelector('.toggle-nav i').classList.toggle('ti-close')
      } else {
        document.querySelector('body').classList.toggle('mini-sidebar')
      }
      val !== undefined ? state.boSidebarOpen = val : state.boSidebarOpen = !state.boSidebarOpen
    }
  },
  actions: {
    async attempt({ commit }) {
      commit('updateUserToken', {
        token: Gen.getCookie('u_auth' + (process.env.VUE_APP_COOKIE_AUTH || ""))
      })
      try {
        let resp = await Gen.apiRest("/get/profile")
        resp = resp.data

        if (resp.data.user) {
          let user = resp.data.user
          if (user.levelId == 23) {
            Gen.apiRest(
              "/do/" + 'UGDAssesment', {
              data: {
                type: 'remove-on-tindak-lanjut',
                id_user: user.id
              }
            },
              "POST"
            )
          }
        }


        if (resp.status == "success") {
          commit('updateUserToken', {
            token: resp.data.token,
            isRememberMe: resp.data.isRemember == "Y" ? true : false
          })
          commit('updateUser', { user: resp.data.user })
          commit('updateVariable', { app : resp.data.app })
        } else {
          commit('updateUser', {})
        }
      } catch (error) {
        commit('updateUser', {})
      }
    }
  },
  strict: process.env.DEV,
})


export default store
